var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"3.12"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

const Sentry = require("@sentry/nextjs");
export const sentryConfig = {
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  release: SENTRY_RELEASE,
  tracesSampleRate: parseFloat(process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE_TRACES),
  replaysSessionSampleRate: parseFloat(process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE_REPLAY_SESSION),
  profilesSampleRate: parseFloat(process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE_PROFILES), // Profiling sample rate is relative to tracesSampleRate
  replaysOnErrorSampleRate: 1.0,
  environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
  enabled: process.env.NEXT_PUBLIC_SENTRY_ENV === 'production' || process.env.NEXT_PUBLIC_SENTRY_ENV === 'staging',
};

// Sentry.init(sentryConfig);


