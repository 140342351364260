import { useRecoilValue, useRecoilState } from 'recoil';
import { AxiosRequestConfig } from 'axios';
import { useQuery } from 'react-query';
import {
  SystemStatusType, systemStatusState,
} from '@/recoils/modules/system-status';
import { toCamelCase } from '@/utils/utils';
import profileState from '@/recoils/modules/profile';
import { fetchWithJSON } from '@/utils/api/fetch';
import { BaseBoResponse } from '@/services/service.type';
import { useAxios } from '../useAxios';
import { mapToInboxItem } from '@/mappers/mapToInboxItem';
import { inboxSelector, insertAndUpdateInboxs, useInbox } from '../useInbox';
import { inboxSystemStatusState } from '@/recoils/modules/inbox';
import { SystemStatusFlag, SystemStatusResponse } from './type';

const useSystemStatus = () => {
  const { boApiInstance } = useAxios();

  const [recoilSystemStatus, setRecoilSystemStatus] = useRecoilState(systemStatusState);
  const [, setInboxSystemStatus] = useRecoilState(inboxSystemStatusState);
  const profile = useRecoilValue(profileState);

  const collectSystemStatus = (data:any) => {
    const systemStatusFlag: SystemStatusFlag = {
      DepositError: false,
      FreeStyle: false,
      FreeStyleSCB: false,
      FreeStyleText: '',
      FreeStyleTextSCB: '',
      FriendInvite: false,
      GameError: false,
      KioskError: false,
      Register: false,
      SCBError: false,
      UnderConstruction: false,
      UnderConstructionText: '',
      Valentine: false,
      WithdrawError: false,
      GameProviderPG: false,
      GameProviderPNG: false,
      GameProviderEVO: false,
      GameProviderJOKER: false,
    };
    const systemStatusResponse: SystemStatusResponse = {
      ...systemStatusFlag,
      Inbox: [],
      Toast: { ...systemStatusFlag },
      ...data,
    };

    const {
      SCBError = false,
      Inbox,
      Toast,
      GameProviderPG,
      GameProviderPNG,
      GameProviderEVO,
      GameProviderJOKER,
      ...excludeSCBErrorStatus
    } = systemStatusResponse;
    const isSCBUser = profile?.data.bank_account_list[0].bank_code === 'SCB';
    const allInbox = Inbox.map(mapToInboxItem).slice().reverse();
    const { system } = inboxSelector(allInbox);
    if (system.length > 0) {
      setInboxSystemStatus((current) => {
        return {
          ...current,
          inboxs: insertAndUpdateInboxs(current.inboxs, system),
        };
      });
    }
    const updatedSystemStatus = {
      ...toCamelCase(excludeSCBErrorStatus),
      toast: toCamelCase(systemStatusResponse.Toast),
      SCBError,
      isSCBUser,
      lastUpdate: new Date(),
      gameProvider: {
        PG: GameProviderPG,
        PNG: GameProviderPNG,
        EVO: GameProviderEVO,
        JOKER: GameProviderJOKER,
      },
    } as SystemStatusType;
    setRecoilSystemStatus(updatedSystemStatus);
    return updatedSystemStatus;
  };
  const { connectInboxSocket } = useInbox(collectSystemStatus);
  const {
    data: systemStatusData,
    refetch: fetchSystemStatus,
    isFetching: isLoading,
  } = useQuery<SystemStatusType>(['SystemStatus'], async () => {
    const requestConfig: AxiosRequestConfig = {
      url: '/v1/feature-toggle/system-status',
      method: 'GET',
    };
    const response = await fetchWithJSON<BaseBoResponse<SystemStatusResponse>>(boApiInstance, requestConfig);
    const result = collectSystemStatus(response.data);
    return result;
  }, {
    enabled: true,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    onSuccess: setRecoilSystemStatus,
  });

  const refreshSystemStatus = async () => {
    try {
      const requestConfig: AxiosRequestConfig = {
        url: '/v1/feature-toggle/system-status',
        method: 'GET',
      };
      const response = await fetchWithJSON<BaseBoResponse<SystemStatusResponse>>(boApiInstance, requestConfig);
      const result = collectSystemStatus(response.data);
      return result;
    } catch (error) {
      return null;
    }
  };
  // const { connectInboxSocket } = useInbox(collectSystemStatus);

  return {
    systemStatus: recoilSystemStatus,
    connectInboxSocket,
    fetchSystemStatus,
    refreshSystemStatus,
    collectSystemStatus,
  };
};

export default useSystemStatus;
