import styled from 'styled-components';
import { screen } from '@/utils/responsive';
import { Button } from '@/components/Button';

export const ForgetPhoneButton = styled.div`
  width: fit-content;
  text-align: center;
  font-size: 16px;
  color: ${({ theme }) => (theme.darkmode ? theme['text-1'] : theme['greyscale-500'])};
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => (theme['primary-400'])};
  }
`;
export const StyledLineButton = styled(Button)`
  width: 100% !important;
  height: 48px;
  margin-bottom: 25px;
  border-color: green !important;
  color: green !important;
  @media ${screen.lg} {
    margin-bottom: 25px;
  }
`;
