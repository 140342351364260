import { atom } from 'recoil';
import { KEYS } from '../keys';

export type MiniToastState = string[];

const miniToastAtom = atom<MiniToastState>({
  key: KEYS.MINI_TOAST,
  default: [],
});

export default miniToastAtom;
