export const KEYS = {
  AUTH_SELECTOR: 'AUTH_SELECTOR',
  BONUS: 'BONUS',
  GAME: 'GAME',
  GAME_CANVAS: 'GAME_CANVAS',
  GAME_BRAND: 'GAME_BRAND',
  GAME_META: 'GAME_META',
  WALLET: 'WALLET',
  REGISTER: 'REGISTER',
  COIN: 'COIN',
  SYSTEM_STATUS: 'SYSTEM_STATUS',
  SYSTEM_STATUS_TOAST: 'SYSTEM_STATUS_TOAST',
  KEYBOARD_FOCUS: 'KEYBOARD_FOCUS',
  RANK_GAME: 'RANK_GAME',
  FETCH_COIN: 'FETCH_COIN',
  PROFILE: 'PROFILE',
  PROFILE_INFO: 'PROFILE_INFO',
  GUILD_INFO: 'GUILD_INFO',
  VIP: 'VIP',
  FEATURE_TOGGLE: 'FEATURE_TOGGLE',
  FAQ: 'FAQ',
  PIG_SHOP: 'PIG_SHOP',
  TUTORIAL_MODAL: 'TUTORIAL_MODAL',
  UI_LANDING_VARIANT: 'UI_LANDING_VARIANT',
  NAV_BAR: 'NAV_BAR',
  FAVORITE_GAME: 'FAVORITE_GAME',
  RECENT_GAME: 'RECENT_GAME',
  RECENT_CARD_GAME: 'RECENT_CARD_GAME',
  CHAT_CHANNEL: 'CHAT_CHANNEL',
  BACK_BUTTON: 'BACK_BUTTON',
  FILTER_CONFIG: 'FILTER_CONFIG',
  SORT_CONFIG: 'SORT_CONFIG',
  NOTIFICATION: 'NOTIFICATION',

  INBOX_ALL: 'INBOX_ALL',
  INBOX_SYSTEM_STATUS: 'INBOX_SYSTEM_STATUS',
  INBOX_AFFILIATE: 'INBOX_AFFILIATE',
  INBOX_TRANSACTION: 'INBOX_TRANSACTION',
  LOBBY: 'LOBBY',

  BIGWIN: 'BIGWIN',
  INCOMING_WINNER: 'INCOMING_WINNER',
  MINI_TOAST: 'MINI_TOAST',
};
